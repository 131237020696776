<template>
<body class="page basicpage light-theme">

    <div id="oc-wrapper">

        <header class="header-v2 header-v2-cn" id="header-v2" style="display: block;">

            <Head></Head>
        </header>

        <div id="app-root" aegis-first-screen-timing="true">
            <div class="product-detail-page" style="position:relative">
                <div class="block-container view-control">
                    <div class="clear-gap" id="3PfU9HZba" data-id="3PfU9HZba">
                        <div class="tpm-live-hero is-hidden"></div>
                        <div role="banner" class="tpm-prod-hero">
                            <div class="tpm-prod-hero__bg-wrap">
                                <div class="tpm-prod-hero__bg">
                                    <div class="tpm-prod-hero__bg-inner" v-if="!m" :style="'background-image:url(&quot;'+ (baseUrl + info.topImg)+' &quot;)'">
                                    </div>
                                    <div class="tpm-prod-hero__bg-inner" v-else :style="'background-image:url(&quot;'+ (baseUrl + info.topImgM)+' &quot;)'">
                                    </div>
                                </div>
                            </div>
                            <div class="tpm-prod-hero__inner">
                                <div class="tpm-prod-hero__seo"><img alt="" src="" /></div>
                                <div class="tpm-prod-hero__content">
                                    <div class="tpm-prod-hero__header">
                                        <h1 class="tpm-prod-hero__tit" v-if="!m" id="hero_tit"><span>{{info.name}}</span></h1>
                                        <h1 class="tpm-prod-hero__tit" v-else id="hero_tit" style="font-size: 24px;line-height: 32px;white-space: pre-wrap;"><span>{{info.name}}</span></h1>
                                    </div>
                                    <p class="tpm-prod-hero__desc" style="color: whitesmoke;">
                                        {{info.descs}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!m" v-html="info.content"></div>
                    <div v-else v-html="info.contentM"></div>
                    

                </div>
            </div>
        </div>

        <span>

            <section class="light-theme">
                <footer id="footer">
                    <Foot></Foot>
                </footer>
            </section>
        </span>

    </div>

</body>
</template>

<script>
import {
    solutionInfo
} from '@/request/api'
import Head from '../../components/heads.vue'
import Foot from '../../components/footer.vue'
export default {
    components: {
        Head,
        Foot
    },
    data() {
        return {
            info: {},
            baseUrl: "https://www.inrunonline.com/api/sys/common/static/",
            m: false
        }
    },
    created() {
        let id = this.$route.params.id
        if (id) {
            solutionInfo({
                id: id
            }).then((res) => {
                this.info = res.result;
            })
        }

        this.m = this.isMobile()

    },
    methods: {
        // 添加判断方法
        isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
            return flag;
        }
    }
}
</script>

<style>
@media screen and (max-width: 768px) {
    :root {
        --qcloud-float-ratio: 40 / 52;
    }
}

@media screen and (max-width: 768px) {
    .tpm-prod-hero {
        margin-top: -57px;
        height: auto;
        min-height: 390px;
        background: #e0e6e8;
        padding: 0;
    }

    .tpm-prod-hero__bg {
        left: 0;
        right: auto;
    }

    .tpm-prod-hero__bg:before {
        width: 200px;
        margin-right: 184px;
    }

    .tpm-prod-hero__bg:after {
        width: 200px;
        margin-left: 184px;
    }

    .tpm-prod-hero__inner {
        padding: 0 16px;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .tpm-prod-hero__content {
        margin-top: 0;
        padding-bottom: 24px;
        padding-top: 270px;
    }

    .tpm-prod-hero__header {
        padding-right: 0;
        margin-bottom: 4px;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .tpm-prod-hero__desc,
    .tpm-prod-hero__tit {
        text-align: center;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }

    .tpm-prod-hero__tit {
        font-size: 24px;
        line-height: 32px;
        white-space: pre-wrap;
    }

    .tpm-prod-hero__desc {
        padding-right: 0;
        font-size: 14px;
        line-height: 24px;
        max-width: 100%;
    }

}

.tpm-prod-hero {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 480px;
    background-color: #f1f3f4;
    position: relative;
    box-sizing: border-box;
    padding: 0 10px;
}

.tpm-prod-hero__bg-wrap {
    overflow: hidden;
}

.tpm-prod-hero__bg,
.tpm-prod-hero__bg-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.tpm-prod-hero__bg,
.tpm-prod-hero__bg-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.tpm-prod-hero__bg {
    z-index: 1;
}

.tpm-prod-hero__bg-inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: auto 530px;
    background-repeat: no-repeat;
    background-position: top;
}

.tpm-prod-hero__inner {
    z-index: 2;
    position: relative;
    height: 100%;
    margin: 0 auto;
    padding-top: 62px;
    max-width: 1200px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
}

.tpm-prod-hero__seo {
    position: fixed;
    left: 0;
    top: -1px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.tpm-prod-hero__header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 16px;
}

.tpm-prod-hero__tit {
    font-size: 40px;
    line-height: 52px;
    font-weight: 500;
    color: whitesmoke;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tpm-prod-hero__desc {
    font-size: 16px;
    line-height: 28px;
    max-width: 700px;
}

.tpm-section__hd {
    margin-bottom: 32px;
    text-align: center;
    -webkit-font-smoothing: antialiased;
}

.tpm-section__title {
    font-weight: 500;
    font-size: 28px;
    color: #000;
    line-height: 48px;
}

.view-control .view-column {
    max-width: 1180px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
}

.tp-grid__layout {
    overflow: hidden;
}

.tp-grid__row.tp-grid--gutter-8n {
    margin-right: -16px;
    margin-bottom: -32px;
    margin-left: -16px;
}

.tp-grid__row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    box-sizing: border-box;
    margin-right: 0;
    margin-left: 0;
}

.tp-grid__row.tp-grid--gutter-8n .tp-grid__col {
    margin-bottom: 32px;
    padding-right: 16px;
    padding-left: 16px;
}

.tp-grid__col--8 {
    display: block;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%;
    -webkit-box-flex: 0;
}

.tp-grid__col {
    display: block;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box;
    padding-right: 0;
    padding-left: 0;
    font-size: 14px;
    -webkit-box-flex: 1;
}

.tpm-feature__item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-font-smoothing: antialiased;
}

.tpm-feature__item-icon {
    display: block;
    width: 48px;
    height: 48px;
    margin-right: 20px;
}

.tpm-feature__item-title {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 18px;
    color: #000;
    line-height: 28px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tpm-feature__item-desc {
    font-size: 14px;
    color: #495770;
    line-height: 22px;
}

@media screen and (min-width: 768px) {
    .bg-gray {
        background-color: #f5f7fa;
    }
}

.tpm-rich-text {
    line-height: 24px;
    font-size: 14px;
    color: #495770;
    -webkit-font-smoothing: antialiased;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.tpm-rich-text .ts-p {
    line-height: 24px;
    font-size: 14px;
}

@media screen and (min-width: 769px) {
    .tl-rich-content .tpm-rich-text>.ts-image {
        max-width: 830px;
    }
}

.tpm-rich-text *+.ts-image {
    margin-top: 32px;
    margin-bottom: 32px;
}

.tpm-rich-text .ts-image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    border: 0;
}

.tl-swiper {
    position: relative;
}

.tl-swiper .swiper-container {
    position: static;
}

.tl-swiper .swiper-container {
    position: initial;
}

.tpm-scene-card__swiper .swiper-container {
    padding-bottom: 20px;
}

.swiper-container-pointer-events {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    box-sizing: content-box;
}

.tpm-scene-card__swiper .swiper-slide {
    padding: 0 10px;
    box-sizing: border-box;
    transition: opacity .3s linear;
    height: auto;
}

.swiper-slide {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}

.tpm-scene-card__item {
    padding: 160px 10px 0;
    height: 100%;
    position: relative;
}

.tpm-scene-card__item-object {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 180px;
    border-radius: 4px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.tpm-scene-card__item-main {
    position: relative;
    /* height: 100%; */
    padding: 20px;
    background-image: linear-gradient(0deg, #fff, #f3f5f8);
    border: 2px solid #fff;
    box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, .1);
    border-radius: 4px;
}

.tpm-scene-card__item-title {
    font-weight: 500;
    font-size: 18px;
    color: #16181a;
    line-height: 26px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tpm-scene-card__item-desc {
    margin-top: 16px;
    font-size: 14px;
    color: #495770;
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    max-height: 96px;
}

.tl-swiper__btn {
    position: absolute;
    top: 0;
    width: 40px;
    height: 40px;
    z-index: 3;
    transform: translateY(-50%);
    border-radius: 50%;
    font-size: 0;
    background-image: linear-gradient(0deg, #fff, #f3f5f8);
    border: 2px solid #fff;
    box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, .1), -8px -8px 20px 0 #fff
}

.tl-swiper__btn,
.tl-swiper__btn-inner {
    display: flex;
    align-items: center;
    justify-content: center
}

.tl-swiper__btn-inner {
    width: 32px;
    height: 32px;
    background-color: #fff;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, .16);
    border-radius: 50%
}

.tl-swiper__btn-inner:after {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-image: linear-gradient(135deg, #dbe2e9, #fff)
}

.tl-swiper__btn:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    margin: -5px 0 0 -3px;
    width: 6px;
    height: 10px
}

.tl-swiper__btn--prev:after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMi40IDVMNiA4Ljc1IDQuOCAxMCAwIDVsMS4yLTEuMjVMNC44IDAgNiAxLjI1eiIgZmlsbD0iI0JBQzFDOSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+)
}

.tl-swiper__btn--prev:hover:after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMi40IDVMNiA4Ljc1IDQuOCAxMCAwIDVsMS4yLTEuMjVMNC44IDAgNiAxLjI1eiIgZmlsbD0iIzAwNTJkOSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+)
}

.tl-swiper__btn--next:after {
    margin-left: -2px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMy42IDVMMCAxLjI1IDEuMiAwIDYgNSA0LjggNi4yNSAxLjIgMTAgMCA4Ljc1eiIgZmlsbD0iI0JBQzFDOSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+)
}

.tl-swiper__btn--next:hover:after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMy42IDVMMCAxLjI1IDEuMiAwIDYgNSA0LjggNi4yNSAxLjIgMTAgMCA4Ljc1eiIgZmlsbD0iIzAwNTJkOSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+)
}

.tl-swiper__btn--prev {
    right: 100%;
    margin-right: 20px
}

.tl-swiper__btn--next {
    left: 100%;
    margin-left: 20px
}

.tl-swiper__btn.swiper-button-disabled {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

@media screen and (min-width:769px)and (max-width:1320px) {
    .tl-swiper .tl-swiper__btn.is-wrapped {
        display: none
    }
}

.tl-swiper__pages {
    margin-top: 40px;
    text-align: center;
    font-size: 0;
}
</style>

<style>
body.app-mode .cp-site-language-tip {
    display: none !important;
}

body.app-mode .cp-notice-bar {
    display: none !important;
}

body.app-mode #oc-header {
    display: none !important;
}

body.app-mode .new-header {
    display: none !important;
}

body.app-mode #header-v2 {
    display: none !important;
}

body.app-mode #footer {
    display: none !important;
}

body.app-mode #oc-container {
    padding-top: 0 !important;
    margin-top: 0 !important;
}

body.app-mode #oc-wrapper {
    padding-top: 0 !important;
}
</style>
<style>
.oh-nav-pcseek {
    position: relative;
    z-index: 999;
}

/* 导航购物车图标 */
@media(max-width: 767.98px) {
    body #footer .contact .contact-tab .tab-card .tab-card-wrap {
        padding-left: 0;
    }
}
</style>

<style>
@media screen and (max-width: 768px) {

    body.support-home-page.show-cookie-tip .sow_black,
    body.support-page-stand.show-cookie-tip .sow_black,
    body.support-page.show-cookie-tip .sow_black {
        bottom: .64rem !important;
    }

    .support-home-page .sow_black,
    .support-page-stand .sow_black,
    .support-page .sow_black {
        bottom: 12px !important;
    }

    .oh-nav-mbseek .user-info .user-pic img {
        width: 100%;
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1919px) {

    body.support-home-page.show-cookie-tip .sow_customerIcon,
    body.support-page-stand.show-cookie-tip .sow_customerIcon,
    body.support-page.show-cookie-tip .sow_customerIcon {
        transition: bottom .3s;
        bottom: 125px !important;
    }

    body.support-home-page .sow_customerIcon,
    body.support-page-stand .sow_customerIcon,
    body.support-page .sow_customerIcon {
        transition: bottom .3s;
        bottom: 68px !important;
    }
}

@media only screen and (min-width: 1920px) and (max-width: 999999px) {

    body.support-home-page.show-cookie-tip .sow_customerIcon,
    body.support-page-stand.show-cookie-tip .sow_customerIcon,
    body.support-page.show-cookie-tip .sow_customerIcon {
        transition: bottom .3s;
        bottom: 142px !important;
    }

    body.support-home-page .sow_customerIcon,
    body.support-page-stand .sow_customerIcon,
    body.support-page .sow_customerIcon {
        transition: bottom .3s;
        bottom: 85px !important;
    }
}
</style>

<style>
#oc-header .oh-nav-submenus-wrapper .pc-nav-series-products .oh-nav-series-product-list .swiper-slide .pTitle {
    max-width: 200px;
}

.header-v2 .header-main-pc .header-right .search::before,
.header-v2 .header-main-mo .header-menu .search::before {
    content: '';
    display: none;
}

div {
    margin: 0;
    padding: 0;
}
</style>

<style>
#footer nav dl {
    width: 200px
}

@media (max-width: 767.98px) {
    #footer nav dl {
        width: 100%
    }
}

@media (max-width: 1024px) {
    body.clean-theme #oc-container {
        margin-top: -50px;
    }

    .header-v2 .header-main-mo .header-menu.search-show {
        overflow-x: unset;
    }

    .header-v2 .header-main-mo .header-menu.search-hide {
        overflow-x: hidden;
    }
}
</style>
